import React from "react"

import ButtonIcon from "../icon/buttonIcon"
import { Col, Container, Row } from "react-bootstrap"

import "../../sass/global/project-in-mind.scss"

const ProjectInMind = ({ title }) => {
  return (
    <section className="project-in-mind">
      <Container>
        <Row className="align-items-center">
          <Col md={5}>
            <h2 className="pim-title">{title}</h2>
          </Col>
          <Col md={7} className="col-link-left d-flex justify-content-end">
            <a
              href="#CQL"
              class="btn wowaction-btn hover-white drift-chat-popup"
            >
              <ButtonIcon />
              <span>Talk to us</span>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ProjectInMind
