import React,{useState} from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ModalVideo from 'react-modal-video'
import PlayIcon from "../icon/playIcon"
import "../../sass/global/modal-video.scss"
import "../../sass/global/portfolio-works.scss"

const VideoPortfolio = ({type}) => {
    const [isOpen, setOpen] = useState(false)

	return (
        <React.Fragment>
			<ModalVideo channel={type.frontmatter.channel} autoplay isOpen={isOpen} videoId={type.frontmatter.videoid} onClose={() => setOpen(false)} />
                <button className="portfolio-link videowrapp" onClick={() => setOpen(true)}>
                    <div className="our-work-content text-left">
                        <div className="work-thumbnail">
                            <div className="thumbnail-overlay d-flex align-items-center justify-content-center">
                                <PlayIcon />
                            </div>
                            <GatsbyImage image={getImage(type.frontmatter.thumbnail)} alt={ type.frontmatter.title } />
                        </div>
                        <div className="work-title">
                        <p className="title mobile-margin-left-right">
                            {type.frontmatter.title}
                        </p>
                        </div>
                        <p className="category">{type.frontmatter.industry}</p>
                    </div>
                </button>  


		</React.Fragment>
	)
}

export default VideoPortfolio
