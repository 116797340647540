import React from "react"
import AllAevPortfolio from "./allAevPortfolio"
import AllLavPortfolio from "./allLavPortfolio"
import AllWbvPortfolio from "./allWbvPortfolio"

import { Container, Row, Col } from "react-bootstrap"
import "../../sass/global/work-tab.scss"

const AllVideoWorks = () => {
    return(
        <section class="our-works portfolio-works">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} className="text-center">
                        <h1 class="sub-title">Explainer Video Portfolio</h1>
                        <p class="sub-desc">We’ve created 200+ videos for clients of all sizes and verticals across the globe in the past 10 years. Watch some of the best here.</p>                        
                    </Col>
                    <Col md={12}>
                        <div className="our-work-tab mt-5">
                            <ul className="d-flex flex-column flex-md-row align-items-center justify-content-center mb-0 pl-0">
                                <li className="pr-md-3 pr-0 mb-md-0 mb-4">
                                <button className="tab-btn active" id="aev" data-id="#animationev">Animated Explainer Videos</button>
                                </li>
                                <li className="px-md-3 px-0  mb-md-0 mb-4">
                                <button className="tab-btn" id="wv" data-id="#whiteboardev">Whiteboard Video</button>
                                </li>
                                <li className="pl-md-3 px-0">
                                <button className="tab-btn" id="lvp" data-id="#liveactionev">Live Video Production</button>
                                </li>                                
                            </ul>
                        </div>
                    </Col>
                    <div className="wmtab-content active" id="animationev">            
                        <AllAevPortfolio />              
                    </div>
                    <div className="wmtab-content" id="whiteboardev">            
                        <AllWbvPortfolio />         
                    </div>
                    <div className="wmtab-content" id="liveactionev">            
                        <AllLavPortfolio />
                    </div>                                        
                </Row>
            </Container>
        </section>
    )
}

export default AllVideoWorks