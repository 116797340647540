import React from "react";
import { Container, Row, Col} from 'react-bootstrap'
import WowForm from "../global/wowForm";

import '../../sass/global/form.scss'

const HomeForm = ({ page }) => {
    return(
        <section className='form-main'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={8}>
                        <h2 className='text-center sub-title text-white'>Got an idea? Let’s talk!</h2>
                        <p className='sub-desc text-white text-center'>Do you have a project in mind? Got a story to tell the world? Or just want to say hello? Shoot us an email or use the contact form below. We will revert in no time.</p>
                        <WowForm pname={page}/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HomeForm