import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import AllVideoWorks from "../../components/videos/allVideoWorks"
import ProjectInMind from "../../components/global/projectInMind"
import HomeForm from "../../components/home/homeForm"
import DirectContact from "../../components/global/directContact"

import videosFeraturedImage from "../../../static/home/wowmakers.jpeg"

export const Head = () => (
  <>
    <SeoHead
      ogImage={videosFeraturedImage}
      title="Explainer Video Portfolio By WowMakers"
      description="Take a look at our explainer video portfolio and watch some of the best explainer videos we’ve ever made."
    />
  </> 
)

const EvVideos = () => {
    return (
      <Layout>
        <Seo
          bclass="ev-portfolio"
          bid="ev-portfolio"
        ></Seo>
        <ScrollIndicationHeightZero />
        <AllVideoWorks />
        <ProjectInMind title="Do you have a story to tell the world?" />
        <HomeForm page="videos"/>
        <DirectContact />
      </Layout>
    )
}
export default EvVideos
